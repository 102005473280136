import {
  Box,
  Button,
  Flex,
  FlexProps,
  Link,
  Stack,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Input,
  DrawerFooter,
  useDisclosure,
  IconButton,
  Icon,
} from "@chakra-ui/core";
import { graphql, Link as GatsbyLink, navigate, useStaticQuery } from "gatsby";
import React from "react";
import { CoreLogo } from "./CoreLogo";
import SEO from "./SEO";

interface BaseWrapperProps {
  showFooter?: boolean;
  variant?: string;
  pageTitle?: string;
  customSEO?: boolean;
}
interface NavigationHeaderProps extends FlexProps {
  variant?: string;
  pageTitle?: string;
  customSEO?: boolean;
}

interface QueryData {
  allCaseStudy: {
    nodes: {
      slug: string;
    }[];
  };
}

export const query = graphql`
  query {
    allCaseStudy(limit: 1) {
      nodes {
        slug
      }
    }
  }
`;

export const NavigationHeader: React.FC<NavigationHeaderProps> = ({
  variant,
  pageTitle,
  customSEO,
  ...rest
}) => {
  const data = useStaticQuery<QueryData>(query);

  const firstCaseStudy = data.allCaseStudy.nodes[0];

  const navBarHeight = "152px";
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Flex
      as="nav"
      direction={["column", "row"]}
      h={["100%", navBarHeight]}
      paddingBottom={["20px", 0]}
      w="100%"
      zIndex={1}
      flexShrink={0}
      paddingX={[0, "20px", "138px"]}
      justify="space-between"
      align="center"
      backgroundColor={
        variant === "secondary" ? "backgroundPrimary" : "blackPrimary"
      }
      {...rest}
    >
      {customSEO !== true && <SEO title={pageTitle} />}
      <Flex
        w="100%"
        justify={["space-between", "left"]}
        align="center"
        paddingX={["25px", 0]}
        paddingTop={["25px", 0]}
      >
        <Box cursor="pointer" onClick={() => navigate("/")}>
          <CoreLogo />
        </Box>
        <Box
          cursor="pointer"
          onClick={onOpen}
          visibility={["visible", "hidden"]}
        >
          <Icon name="menuIcon" size="20px" />
        </Box>
      </Flex>
      <Flex
        direction={["column", "row"]}
        justify="center"
        align="center"
        h={[0, "100%"]}
        visibility={["hidden", "visible"]}
      >
        <GatsbyLink to={"/case-studies/" + firstCaseStudy.slug}>
          <Button
            _hover={{
              color:
                variant === "secondary" ? "backgroundPrimary" : "blackPrimary",
              bg:
                variant === "secondary" ? "blackPrimary" : "backgroundPrimary",
            }}
            w="150px"
            h="45px"
            mr={[0, "30px", "90px"]}
            backgroundColor={
              variant === "secondary" ? "backgroundPrimary" : "blackPrimary"
            }
            color={variant === "secondary" ? "blackPrimary" : "whitePrimary"}
            fontSize="12px"
            textTransform="uppercase"
          >
            Case Studies
          </Button>
        </GatsbyLink>
        <GatsbyLink to="/apply-now">
          <Button
            w="150px"
            h="45px"
            _hover={{
              color:
                variant === "secondary" ? "backgroundPrimary" : "blackPrimary",
              bg:
                variant === "secondary" ? "blackPrimary" : "backgroundPrimary",
            }}
            backgroundColor={
              variant === "secondary" ? "backgroundPrimary" : "blackPrimary"
            }
            mr={[0, "30px", "90px"]}
            color={variant === "secondary" ? "blackPrimary" : "whitePrimary"}
            fontSize="12px"
            textTransform="uppercase"
          >
            Apply Now
          </Button>
        </GatsbyLink>
        <GatsbyLink to="/network">
          <Button
            w="150px"
            h="45px"
            bg="redSecondary"
            color="whitePrimary"
            fontSize="12px"
          >
            INNOVATION MAP
          </Button>
        </GatsbyLink>
      </Flex>

      <Drawer isOpen={isOpen} placement="top" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerBody>
            <Stack w="100%" spacing="20px" align="center">
              <GatsbyLink to={"/case-studies/" + firstCaseStudy.slug}>
                <Text mb="10px" color="blackPrimary">
                  Case Studies
                </Text>
              </GatsbyLink>
              <GatsbyLink to="/apply-now">
                <Text mb="10px" color="blackPrimary">
                  Apply Now
                </Text>
              </GatsbyLink>
              <GatsbyLink to="/network">
                <Text mb="10px" color="blackPrimary">
                  INNOVATION MAP
                </Text>
              </GatsbyLink>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};
export const HomePageLayoutWrapper: React.FC<BaseWrapperProps> = ({
  variant,
  children,
  showFooter,
  customSEO,
  pageTitle,
}) => {
  const Footer: React.FC = () => {
    return (
      <Flex
        marginX="auto"
        paddingX={["10px", "10px", 0]}
        direction="column"
        width={["100%", "100%", "920px"]}
        backgroundColor="backgroundPrimary"
        h="330px"
        justify="space-between"
      >
        <Flex
          flexGrow={1}
          direction={["row"]}
          w="100%"
          zIndex={1}
          flexShrink={0}
          justify="space-between"
          paddingTop="65px"
        >
          <Stack>
            <Text color="blackPrimary" fontWeight="bold">
              Core Innovation Hub
            </Text>
            <Text color="blackPrimary" w={["117px", "100%"]}>
              Lot Fourteen, North Terrace, <br />
              Adelaide Tonsley Innovation District,
              <br />
              Clovelly Park SOUTH AUSTRALIA
            </Text>
            <Text color="blackPrimary">info@corehub.com.au</Text>
          </Stack>
          <Stack>
            <Flex direction={["column", "row"]}>
              <Text color="blackPrimary" fontWeight="bold" mr="80px">
                Links
              </Text>
              <Stack spacing="30px">
                <Link color="blackPrimary" href="http://www.corehub.com.au">
                  Core Hub
                </Link>
                <Link
                  color="blackPrimary"
                  href="http://www.corehub.com.au/contact-location-1"
                >
                  Contact
                </Link>
              </Stack>
            </Flex>
          </Stack>
        </Flex>
        <Flex justify="space-between" mb={["10px", "65px"]}>
          <Link color="gray.300">Privacy Policy</Link>
          <Text color="gray.300">
            © {new Date().getFullYear()} CORE Hub Pty Ltd
          </Text>
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex
      direction="column"
      h="100%"
      minH="100vh"
      backgroundColor={
        variant === "secondary" ? "backgroundPrimary" : "blackPrimary"
      }
    >
      <NavigationHeader
        variant={variant}
        customSEO={customSEO}
        pageTitle={pageTitle}
      />

      <Flex direction="column" justifyContent="center">
        {children}
        {showFooter && <Footer />}
      </Flex>
    </Flex>
  );
};
